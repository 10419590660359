import React, { useState, useCallback, useEffect } from 'react';
import { Search, Twitter } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Input } from "./ui/input.jsx"
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
  Line
} from "react-simple-maps";
import { geoContains } from "d3-geo";

const GRID_SIZE = 20;
const GRID_STYLES = {
  primary: {
    stroke: "#000000",
    strokeWidth: 0.8,
    strokeOpacity: 0.5
  },
  secondary: {
    stroke: "#9CA3AF",
    strokeWidth: 0.4,
    strokeOpacity: 0.3
  }
};

const bounds = {
  minLong: -125,
  maxLong: -67,
  minLat: 25,
  maxLat: 49
};

const PurchaseMap = ({ transactions }) => {
  const [searchWallet, setSearchWallet] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [position, setPosition] = useState({ coordinates: [-98.5, 39.8], zoom: 1 });
  const [mapData, setMapData] = useState(null);
  const [gridLines, setGridLines] = useState({ horizontal: [], vertical: [] });
  const [focusedCoordinates, setFocusedCoordinates] = useState(null);

  const handleTweetShare = (transaction) => {
    const tweetText = `🪖 It's time for a change. Join the resistance, save the trenches.
Sign up too!🪖
Just made a purchase of ${transaction.tokenAmount} tokens!\n\n #SST`;
    const encodedTweet = encodeURIComponent(tweetText);
    window.open(`https://twitter.com/intent/tweet?text=${encodedTweet}`, '_blank');
  };

  useEffect(() => {
    fetch('https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json')
      .then(response => response.json())
      .then(topology => {
        const { features } = require('topojson-client').feature(topology, topology.objects.states);
        setMapData({ type: "FeatureCollection", features });
        generateGrid();
      })
      .catch(error => console.error('Error loading map:', error));
  }, []);

  const generateGrid = () => {
    const horizontal = [];
    const vertical = [];
    const lonStep = (bounds.maxLong - bounds.minLong) / GRID_SIZE;
    const latStep = (bounds.maxLat - bounds.minLat) / GRID_SIZE;

    for (let i = 0; i <= GRID_SIZE; i++) {
      const lat = bounds.minLat + (i * latStep);
      horizontal.push({
        coordinates: [[bounds.minLong, lat], [bounds.maxLong, lat]],
        isPrimary: i % 5 === 0
      });
    }

    for (let i = 0; i <= GRID_SIZE; i++) {
      const lon = bounds.minLong + (i * lonStep);
      vertical.push({
        coordinates: [[lon, bounds.minLat], [lon, bounds.maxLat]],
        isPrimary: i % 5 === 0
      });
    }

    setGridLines({ horizontal, vertical });
  };

  const isPointInUSA = useCallback((coordinates) => {
    if (!mapData || !mapData.features) return false;
    return mapData.features.some(feature =>
      geoContains(feature, coordinates)
    );
  }, [mapData]);

  const generateLocation = useCallback((hash) => {
    let attempts = 0;
    const maxAttempts = 100;

    while (attempts < maxAttempts) {
      const gridX = (hash + attempts) % GRID_SIZE;
      const gridY = Math.floor((hash + attempts) / GRID_SIZE) % GRID_SIZE;

      const longitude = bounds.minLong + (gridX * ((bounds.maxLong - bounds.minLong) / GRID_SIZE));
      const latitude = bounds.minLat + (gridY * ((bounds.maxLat - bounds.minLat) / GRID_SIZE));

      if (isPointInUSA([longitude, latitude])) {
        return [longitude, latitude];
      }
      attempts++;
    }

    return [-98.5, 39.8];
  }, [isPointInUSA]);

  const handleSearch = useCallback((wallet) => {
    setSearchWallet(wallet);

    if (!wallet) {
      setSelectedLocation(null);
      setFocusedCoordinates(null);
      return;
    }

    const buyTransactions = transactions?.filter(tx => tx.txType === 'buy') || [];
    const foundTx = buyTransactions.find(tx =>
      tx.traderPublicKey.toLowerCase().includes(wallet.toLowerCase())
    );

    if (foundTx) {
      const hash = foundTx.traderPublicKey
        .split('')
        .reduce((acc, char) => char.charCodeAt(0) + acc, 0);
      const coordinates = generateLocation(hash);

      setSelectedLocation(foundTx);
      setFocusedCoordinates(coordinates);

      // Ajustar el zoom cuando se encuentra una ubicación
      setPosition(prev => ({
        ...prev,
        coordinates: [coordinates[0], coordinates[1]],
        zoom: 4
      }));
    } else {
      setSelectedLocation(null);
      setFocusedCoordinates(null);
    }
  }, [transactions, generateLocation]);

  useEffect(() => {
    // Restaurar el zoom cuando se limpia la búsqueda
    if (!searchWallet) {
      setPosition(prev => ({
        ...prev,
        coordinates: [0, 0],
        zoom: 1
      }));
    }
  }, [searchWallet]);

  if (!mapData) {
    return (
      <Card className="w-full max-w-4xl mx-auto">
        <CardContent className="p-8 text-center">
          Loading map...
        </CardContent>
      </Card>
    );
  }

  const buyTransactions = transactions?.filter(tx => tx.txType === 'buy') || [];


  return (
    <Card className="w-full max-w-4xl mx-auto">
      <CardHeader className="flex flex-row items-center justify-between">
        <div className="relative w-full max-w-sm">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
          <Input
            type="text"
            placeholder="Search by wallet address..."
            className="pl-8"
            value={searchWallet}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </CardHeader>
      <CardContent>
        <div className="aspect-[4/3] w-full rounded-lg overflow-hidden border border-gray-200">
          <ComposableMap
            projection="geoAlbersUsa"
            projectionConfig={{
              scale: 800
            }}
            style={{
              background: '#a4a4eb' // Fondo azul claro
            }}
          >
            <ZoomableGroup
              zoom={position.zoom}
              center={position.coordinates}
              onMoveEnd={setPosition}
            >
              <rect
                x={-1000}
                y={-1000}
                width={2000}
                height={2000}
                fill="#a4a4eb"
              />
              <Geographies geography={mapData}>
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill="transparent"
                      stroke="#D1D5DB"    // Color del borde más claro
                      strokeWidth={4}      // Borde más grueso
                      style={{
                        default: {
                          fill: "transparent",
                          stroke: "#D1D5DB",
                          strokeWidth: 3,
                          outline: "none"
                        },
                        hover: {
                          fill: "rgba(209, 213, 219, 0.1)",
                          stroke: "#E5E7EB",  // Borde más claro en hover
                          strokeWidth: 4,    // Borde más grueso en hover
                          outline: "none"
                        }
                      }}
                    />
                  ))
                }
              </Geographies>

              {gridLines.horizontal.map((line, i) => (
                <Line
                  key={`h-${i}`}
                  from={line.coordinates[0]}
                  to={line.coordinates[1]}
                  {...(line.isPrimary ? GRID_STYLES.primary : GRID_STYLES.secondary)}
                />
              ))}

              {gridLines.vertical.map((line, i) => (
                <Line
                  key={`v-${i}`}
                  from={line.coordinates[0]}
                  to={line.coordinates[1]}
                  {...(line.isPrimary ? GRID_STYLES.primary : GRID_STYLES.secondary)}
                />
              ))}

              {buyTransactions.map((tx) => {
                const hash = tx.traderPublicKey
                  .split('')
                  .reduce((acc, char) => char.charCodeAt(0) + acc, 0);
                const coordinates = generateLocation(hash);
                const isSelected = selectedLocation?.traderPublicKey === tx.traderPublicKey;

                return (
                  <Marker
                    key={tx.traderPublicKey}
                    coordinates={coordinates}
                  >
                    <circle
                      r={isSelected ? 6 : 3}
                      fill={isSelected ? "#EF4444" : "#DC2626"}
                      stroke={isSelected ? "#FEE2E2" : "none"}
                      strokeWidth={2}
                      className={`
                        cursor-pointer
                        transition-all duration-300
                        ${isSelected ? 'animate-pulse' : ''}
                      `}
                      onClick={() => {
                        setSelectedLocation(tx);
                        setSearchWallet(tx.traderPublicKey);
                        setPosition(prev => ({
                          ...prev,
                          coordinates: coordinates,
                          zoom: 4
                        }));
                      }}
                    />
                  </Marker>
                );
              })}
            </ZoomableGroup>
          </ComposableMap>
        </div>

        {selectedLocation && (
          <div className="mt-4 p-4 bg-gray-50 rounded-lg">
            <h3 className="font-medium">Purchase Details</h3>
            <div className="mt-2 space-y-1 text-sm">
              <p>Wallet: {selectedLocation.traderPublicKey}</p>
              <p>Amount: {selectedLocation.tokenAmount} tokens</p>
              <p>Time: {new Date(selectedLocation.timestamp).toLocaleString()}</p>
              <div className="mt-4">
                <button
                  onClick={() => handleTweetShare(selectedLocation)}
                  className="flex items-center gap-2 bg-[#1DA1F2] hover:bg-[#1a8cd8]"
                >
                  <Twitter size={16} />
                  Share on Twitter
                </button>
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PurchaseMap;