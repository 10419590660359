import { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, where, onSnapshot, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../firebase';

// Configuración
const TOKEN_PUBLIC_KEY = 'GYAeWxz3Hw76bZdkTmEpnccPrCuDPRTF4yEFDnkxpump';
const QUICKNODE_RPC = 'https://quaint-silent-sun.solana-mainnet.quiknode.pro/583c48688406d493ad202959bf0a3d7e9965fdf3';
const MIN_TOKEN_AMOUNT = 0.01;
const REFRESH_INTERVAL = 30000;

// Control de modo: true para usar datos simulados, false para usar Firebase
const USE_MOCK = true;

// Funciones auxiliares para el mock
const generateRandomWallet = () => {
  return Array.from({ length: 44 }, () => 
    '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz'[
      Math.floor(Math.random() * 58)
    ]
  ).join('');
};

const generateMockData = () => {
  const mockTransactions = Array.from({ length: 30 }, (_, i) => ({
    id: `tx-${i}`,
    signature: `sig-${i}`,
    timestamp: Date.now() - Math.floor(Math.random() * 86400000),
    txType: 'buy',
    tokenAmount: Math.random() * 100 + 1,
    traderPublicKey: generateRandomWallet()
  }));

  mockTransactions.sort((a, b) => b.timestamp - a.timestamp);

  const mockTopBuys = Array.from({ length: 20 }, (_, i) => ({
    id: `top-${i}`,
    signature: `sig-top-${i}`,
    timestamp: Date.now() - Math.floor(Math.random() * 86400000),
    txType: 'buy',
    tokenAmount: Math.random() * 500 + 100,
    traderPublicKey: generateRandomWallet()
  })).sort((a, b) => b.tokenAmount - a.tokenAmount);

  const mockMarketInfo = {
    marketCapSol: 150000 + Math.random() * 50000,
    vSolInBondingCurve: 75000 + Math.random() * 25000,
    vTokensInBondingCurve: 1000000 + Math.random() * 500000
  };

  return {
    transactions: mockTransactions,
    topBuys: mockTopBuys,
    marketInfo: mockMarketInfo
  };
};

export const useFirebaseData = () => {
  const [transactions, setTransactions] = useState([]);
  const [topBuys, setTopBuys] = useState([]);
  const [marketInfo, setMarketInfo] = useState({
    marketCapSol: 0,
    vSolInBondingCurve: 0,
    vTokensInBondingCurve: 0
  });

  useEffect(() => {
    if (USE_MOCK) {
      // Modo Mock
      const mockData = generateMockData();
      setTransactions(mockData.transactions);
      setTopBuys(mockData.topBuys);
      setMarketInfo(mockData.marketInfo);

      // Actualización periódica completa
      const fullUpdateInterval = setInterval(() => {
        const newMockData = generateMockData();
        setTransactions(newMockData.transactions);
        setTopBuys(newMockData.topBuys);
        setMarketInfo(newMockData.marketInfo);
      }, REFRESH_INTERVAL);

      // Actualización frecuente de transacciones individuales
      const transactionUpdateInterval = setInterval(() => {
        const newTransaction = {
          id: `tx-${Date.now()}`,
          signature: `sig-${Date.now()}`,
          timestamp: Date.now(),
          txType: 'buy',
          tokenAmount: Math.random() * 100 + 1,
          traderPublicKey: generateRandomWallet()
        };

        setTransactions(prev => [newTransaction, ...prev.slice(0, -1)]);

        if (newTransaction.tokenAmount > 100) {
          setTopBuys(prev => {
            const newTopBuys = [newTransaction, ...prev];
            newTopBuys.sort((a, b) => b.tokenAmount - a.tokenAmount);
            return newTopBuys.slice(0, 20);
          });
        }
      }, 5000);

      return () => {
        clearInterval(fullUpdateInterval);
        clearInterval(transactionUpdateInterval);
      };
    } else {
      // Modo Firebase Real
      
      // Efecto para obtener datos del token y guardarlos en Firebase
      const fetchTokenData = async () => {
        try {
          const response = await fetch(QUICKNODE_RPC, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              jsonrpc: '2.0',
              id: 1,
              method: 'getSignaturesForAddress',
              params: [TOKEN_PUBLIC_KEY, { limit: 50, commitment: 'confirmed' }]
            })
          });

          const signaturesData = await response.json();
          if (signaturesData.error) throw new Error(signaturesData.error.message);

          const transactionPromises = signaturesData.result.map(async (signatureInfo) => {
            const txResponse = await fetch(QUICKNODE_RPC, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                jsonrpc: '2.0',
                id: 1,
                method: 'getTransaction',
                params: [signatureInfo.signature, { encoding: 'json', maxSupportedTransactionVersion: 0 }]
              })
            });

            const txData = await txResponse.json();
            if (txData.error || !txData.result) return null;

            const tx = txData.result;
            let txType = 'unknown';
            let tokenAmount = 0;

            if (tx.meta && tx.meta.preTokenBalances && tx.meta.postTokenBalances) {
              const tokenBalanceChange = tx.meta.postTokenBalances.reduce((acc, post) => {
                const pre = tx.meta.preTokenBalances.find(p => p.accountIndex === post.accountIndex);
                if (pre && pre.uiTokenAmount && post.uiTokenAmount) {
                  return acc + (post.uiTokenAmount.uiAmount - pre.uiTokenAmount.uiAmount);
                }
                return acc;
              }, 0);

              tokenAmount = Math.abs(tokenBalanceChange);
              if (tokenAmount >= MIN_TOKEN_AMOUNT) {
                txType = tokenBalanceChange > 0 ? 'buy' : null;
              }
            }

            if (txType === 'buy') {
              const transaction = {
                signature: signatureInfo.signature,
                timestamp: tx.blockTime * 1000,
                txType,
                tokenAmount,
                traderPublicKey: tx.transaction.message.accountKeys[0].toString()
              };

              try {
                await addDoc(collection(db, 'transactions'), transaction);
              } catch (error) {
                console.error('Error saving transaction:', error);
              }

              return transaction;
            }

            return null;
          });

          const txResults = await Promise.all(transactionPromises);
          const validTransactions = txResults.filter(tx => tx !== null);

          if (validTransactions.length > 0) {
            const marketUpdate = {
              marketCapSol: validTransactions.reduce((acc, tx) => acc + tx.tokenAmount, 0),
              timestamp: Date.now()
            };

            try {
              await addDoc(collection(db, 'marketInfo'), marketUpdate);
            } catch (error) {
              console.error('Error saving market info:', error);
            }
          }
        } catch (error) {
          console.error('Error fetching token data:', error);
        }
      };

      // Cargar datos históricos
      const loadHistoricalData = async () => {
        try {
          const txSnapshot = await getDocs(
            query(
              collection(db, 'transactions'),
              where('txType', '==', 'buy'),
              orderBy('timestamp', 'desc'),
              limit(30)
            )
          );
          setTransactions(txSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

          const topBuysSnapshot = await getDocs(
            query(
              collection(db, 'transactions'),
              where('txType', '==', 'buy'),
              orderBy('tokenAmount', 'desc'),
              limit(20)
            )
          );
          setTopBuys(topBuysSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

          const marketInfoSnapshot = await getDocs(
            query(collection(db, 'marketInfo'), orderBy('timestamp', 'desc'), limit(1))
          );
          if (!marketInfoSnapshot.empty) {
            setMarketInfo(marketInfoSnapshot.docs[0].data());
          }
        } catch (error) {
          console.error('Error loading historical data:', error);
        }
      };

      // Iniciar la carga de datos y configurar listeners
      loadHistoricalData();
      fetchTokenData();
      const fetchInterval = setInterval(fetchTokenData, REFRESH_INTERVAL);

      // Configurar listeners de Firebase
      const unsubscribeTransactions = onSnapshot(
        query(
          collection(db, 'transactions'),
          where('txType', '==', 'buy'),
          orderBy('timestamp', 'desc'),
          limit(30)
        ),
        (snapshot) => setTransactions(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
      );

      const unsubscribeTopBuys = onSnapshot(
        query(
          collection(db, 'transactions'),
          where('txType', '==', 'buy'),
          orderBy('tokenAmount', 'desc'),
          limit(20)
        ),
        (snapshot) => setTopBuys(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
      );

      const unsubscribeMarketInfo = onSnapshot(
        query(collection(db, 'marketInfo'), orderBy('timestamp', 'desc'), limit(1)),
        (snapshot) => {
          if (!snapshot.empty) {
            setMarketInfo(snapshot.docs[0].data());
          }
        }
      );

      return () => {
        clearInterval(fetchInterval);
        unsubscribeTransactions();
        unsubscribeTopBuys();
        unsubscribeMarketInfo();
      };
    }
  }, []);

  return { transactions, topBuys, marketInfo };
};