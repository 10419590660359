import React from 'react';

const Header = () => {
  return (
    <header className="bg-black/80 backdrop-blur-sm shadow-lg fixed top-0 w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20"> {/* Aumentado height de h-16 a h-20 */}
          {/* Logo/Left section */}
          <div className="w-1/3">
          </div>

          {/* Title image */}
          <div className="flex justify-center -mb-4"> {/* Eliminado mb-8 y añadido -mb-4 para ajustar */}
            <img 
              src="/title.png" 
              alt="Sol Hotel" 
              className="max-w-[400px] mt-2" /* Añadido mt-2 para margen superior */
            />
          </div>

          {/* Social/Right section */}
          <div className="w-1/3 flex justify-end">
            <a
              href="https://t.me/SolanaStateofTrenches"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center hover:opacity-80 transition-opacity"
            >
              <img
                src="/x.png"
                alt="Twitter"
                className="w-8 h-8"
              />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;