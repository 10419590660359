import React from 'react';
import Header from './components/Header';
import MarketInfo from './components/MarketInfo';
import TopBuys from './components/TopBuys';
import TransactionsSidebar from './components/TransactionsSidebar';
import { formatters } from './utils/formatters';
import PurchaseMap from './components/PurchaseMap';
import { useFirebaseData } from './hooks/useFirebaseData';

const TokenLiveTracker = () => {
  const { transactions, topBuys, marketInfo } = useFirebaseData();

  return (
    <div
      className="min-h-screen relative"
      style={{
        backgroundImage: 'url(/bg.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed'
      }}
    >
      <Header />

      <div className="flex min-h-screen pt-16"> {/* Agregado min-h-screen */}
        <div className="flex-1 flex items-center justify-center"> {/* Agregado items-center para centrado vertical */}
          <main className="max-w-4xl w-full flex items-center"> {/* Agregado items-center y w-full */}
            <div className="w-full p-6">
              <PurchaseMap
                transactions={transactions}
              />
            </div>
          </main>
        </div>

        <TransactionsSidebar
          transactions={transactions}
          formatTokenAmount={formatters.tokenAmount}
          formatWallet={formatters.wallet}
          formatTimestamp={formatters.timestamp}
        />
      </div>
    </div>
  );
};

export default TokenLiveTracker;