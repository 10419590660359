// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDi6XhCfxPlx489a_NBeVdsZzbSJZRzAoU",
  authDomain: "market2-be8ec.firebaseapp.com",
  projectId: "market2-be8ec",
  storageBucket: "market2-be8ec.firebasestorage.app",
  messagingSenderId: "875708600647",
  appId: "1:875708600647:web:265d9f7412dc27c7c6bc62",
  measurementId: "G-14Q7CY182K"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const analytics = getAnalytics(app);